import {
  loginError,
  loginInit,
  loginSucess,
} from "../../store/slice/authSlice";
import {
  dashBoardData,
  dashboardError, dashbordSuccessPayment,
  dashbordSuccessUsers,
  dsshbordInit
} from "../../store/slice/homeSlice";
import { packageError, packageInit, packageSuccess } from "../../store/slice/packagesSlice";
import { profileError, profileInit, profileSuccess } from "../../store/slice/profileSlice";
import { subsError, subsInit, subsSuccess } from "../../store/slice/subsctibtionSlice";
import { usersError, usersInit, usersSuccess } from "../../store/slice/userSlice";
import { DASHBOARD, DASHBOARD_PAYMENT, DASHBOARD_USER, LOGIN_URL, PACKAGE_LIST, PAYMENTS, PROFILE_BY_ID, USERS_LIST } from "../apiEndPoint";
import { METHOD, apiCall } from "../baseApiCall";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export const login = (userData) => (dispatch) => {
  dispatch(loginInit());
  apiCall(
    LOGIN_URL,
    { ...userData, deviceId: localStorage.getItem("deviceId") || "dafshkdjg" },
    (data) => {
      dispatch(loginSucess(data));
    },
    () => {
      dispatch(loginError());
    },
    METHOD.POST,
    { showToast: true }
  );
};

export const dashboardHome = (userData) => (dispatch) => {
  dispatch(dsshbordInit());
  apiCall(
    `${DASHBOARD_USER}`,
    {},
    (data) => {
      dispatch(dashbordSuccessUsers(data));
    },
    () => {
      dispatch(dashboardError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const dashboardApi = (userData) => (dispatch) => {
  dispatch(dsshbordInit());
  apiCall(
    `${DASHBOARD}`,
    {},
    (data) => {
      dispatch(dashBoardData(data));
    },
    () => {
      dispatch(dashboardError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const dashBoardPayment = () => (dispatch) => {

  dispatch(dsshbordInit());
  apiCall(
    DASHBOARD_PAYMENT,
    {},
    (data) => {
      dispatch(dashbordSuccessPayment(data));
    },
    () => {
      dispatch(dashboardError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const subscrioptionList = (currentPage) => (dispatch) => {
  dispatch(subsInit());
  apiCall(
    `${PAYMENTS}?currentPage=${currentPage}&recordPerPage=10`,
    {},
    (data) => {
      console.log("========> da", data);
      dispatch(subsSuccess(data));
    },
    () => {
      dispatch(subsError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const usersListApi = (current, word='', startDate, endDate) => (dispatch) => {
  const formattedStartDate = startDate ? dayjs(startDate).toISOString() : '';
  const formattedEndDate = endDate ? dayjs(endDate).toISOString() : '';
  dispatch(usersInit());
  apiCall(
    `${USERS_LIST}?currentPage=${current}&word=${word}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&recordPerPage=10`,
    {},
    (data) => {
      console.log("========> da", data);
      dispatch(usersSuccess(data));
    },
    () => {
      dispatch(usersError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const profileAPi = (id) => (dispatch) => {
  dispatch(profileInit());
  apiCall(
    `${PROFILE_BY_ID}/${id}`,
    {},
    (data) => {
      dispatch(profileSuccess(data));
    },
    () => {
      dispatch(profileError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const packagesApi = (currentPage) => (dispatch) => {
  dispatch(packageInit());
  apiCall(
    `${PACKAGE_LIST}?currentPage=${currentPage}&recordPerPage=10`,
    {},
    (data) => {
      console.log('====================================');
      console.log("gart");
      console.log('====================================');
      dispatch(packageSuccess(data));
    },
    () => {
      dispatch(packageError());
    },
    METHOD.GET,
    { showToast: true }
  );
};

export const addPlan = (plan, currentPage) => (dispatch) => {
  dispatch(packageInit());
  apiCall(
    `${PACKAGE_LIST}`,
    {
      amount: Number(plan.amount),
      validity: plan.validity,
      description: plan.description,
      isActive: true,
    },
    () => {
      toast.success("plan added succesfully");
      dispatch(packagesApi(currentPage))
    },
    () => {
      dispatch(packageError());
    },
    METHOD.POST,
    { showToast: true }
  );
};

export const deletePlan = (id) => (dispatch) => {
  dispatch(packageInit());
  apiCall(
    `${PACKAGE_LIST}/${id}`,
    {
     
    },
    () => {
      toast.success("plan deleted succesfully");
      dispatch(packagesApi(1));
    },
    () => {
      dispatch(packageError());
    },
    METHOD.DELETE,
    { showToast: true }
  );
};

export const updatePlan = (plan, currentPage) => (dispatch) => {
  
  dispatch(packageInit());
  apiCall(
    `${PACKAGE_LIST}/${plan.id}`,
    {
      amount: Number(plan.amount),
      validity: plan.validity,
      description: plan.description,
      isActive: true,
    },
    (data) => {
      toast.success("plan edited succesfully");
      dispatch(packagesApi(currentPage))
    },
    () => {
      dispatch(packageError());
    },
    METHOD.PUT,
    { showToast: true }
  );
};
